import { ButtonTheme as ButtonThemeBase } from '@/components/standalone/Button/theme';
import { TextFieldTheme as TextFieldBase } from '@/components/standalone/TextField/theme';
import { tw } from '@/utils/tw';

const TextFieldTheme = tw.theme({
  extend: TextFieldBase,
  slots: {
    base: [
      'last:mb-3',
      'md:has-[input[name=firstName]]:flex-1',
      'md:has-[input[name=lastName]]:flex-1',
      'md:has-[input[name=email]]:min-w-full',
    ],
  },
  defaultVariants: {
    variant: 'secondary',
  },
});

const ButtonTheme = tw.theme({
  extend: ButtonThemeBase,
  base: ['md:max-w-fit', 'md:block', 'md:mx-auto', 'mb-4', 'md:mb-5'],
  defaultVariants: {
    size: 'large',
    fill: true,
  },
});

const ArticlePaywallFormTheme = tw.theme({
  slots: {
    base: ['mb-3', 'sm:mb-4', 'md:px-13.5'],
    policy: ['mb-6'],
    caption: ['max-w-58', 'md:max-w-full', 'mx-auto', 'text-center', 'mb-5'],
    fieldset: ['flex', 'flex-col', 'md:flex-row', 'md:flex-wrap', 'gap-4', 'md:gap-x-6', 'md:gap-y-4'],
    provider: ['hidden', 'md:flex', 'md:items-center', 'md:justify-center'],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  Button: ButtonTheme,
  TextField: TextFieldTheme,
});
