import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { zodResolver } from '@hookform/resolvers/zod';
import { ComponentProps, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ArticlePaywallForm } from './ArticlePaywallForm';

export interface ArticlePaywallFormProps extends StandaloneComponentProps<typeof ArticlePaywallForm> {
  button?: ReactNode;
  caption?: ReactNode;
  description?: ReactNode;
  validationSchema?: z.ZodObject<any, any>;
  headline?: ReactNode;
  policy?: ReactNode;
  provider?: ReactNode;
  textFields?: ComponentProps<typeof ArticlePaywallForm.TextField>[];
}

export const DefaultArticlePaywallFormValidationSchema = z.object({
  firstName: z
    .string()
    .nonempty('* Detta fält är obligatoriskt')
    .min(2, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken')
    .max(255, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken'),
  lastName: z
    .string()
    .nonempty('* Detta fält är obligatoriskt')
    .min(2, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken')
    .max(255, '* Fältet ska innehålla minst 2 tecken och högst 255 tecken'),
  email: z.string().nonempty('* Var vänlig ange din e-postadress').email('* Vänligen ange en giltig e-postadress'),
});

const defaultTextFields = [
  { name: 'firstName', label: 'Förnamn' },
  { name: 'lastName', label: 'Efternamn' },
  { name: 'email', label: 'Din mejladress', type: 'email' },
];

export const StandaloneArticlePaywallForm: StandaloneComponent<ArticlePaywallFormProps> = ({
  button = 'överlämna',
  caption,
  validationSchema,
  policy,
  provider,
  textFields,
  ...props
}) => {
  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(validationSchema || DefaultArticlePaywallFormValidationSchema),
  });

  const { createPasswordlessAccount } = useUserAuth();

  return (
    <ArticlePaywallForm
      onSubmit={handleSubmit(async (values) => {
        await createPasswordlessAccount(values as Parameters<typeof createPasswordlessAccount>[0], false);
      })}
      {...props}
    >
      <ArticlePaywallForm.Fieldset>
        {(textFields || defaultTextFields).map((textField, index) => (
          <ArticlePaywallForm.TextField
            key={index}
            caption={formState.errors[textField.name as string]?.message as string}
            label={textField.label}
            options={mergeOptions(
              { $input: { type: textField.type ?? 'text', ...register(textField.name as string) } },
              props?.options?.$textField,
            )}
            status={formState.errors[textField.name as string] ? 'error' : 'default'}
          />
        ))}
      </ArticlePaywallForm.Fieldset>
      {policy && <ArticlePaywallForm.Policy>{policy}</ArticlePaywallForm.Policy>}
      <ArticlePaywallForm.Button content={button} options={{ type: 'submit', ...props.options?.$button }} />
      {caption && <ArticlePaywallForm.Caption>{caption}</ArticlePaywallForm.Caption>}
      {provider && <ArticlePaywallForm.Provider>{provider}</ArticlePaywallForm.Provider>}
    </ArticlePaywallForm>
  );
};
